<template>
    <div>
        <a-modal width="800px" :maskClosable="false" title="结束工单" :visible="true" :confirm-loading="confirmLoading"
            @ok="handleOk" @cancel="handleCancel">
            <a-form-model ref="ruleForm" :model="form" :rules="rules">
                <h3 style="margin-top:0">回复信息</h3>
                <a-form-model-item ref="replyUserText" :colon="false" prop="replyUserText">
                    <template #label>
                        <span>回复用户内容</span>
                        <!-- <a-button type="link" @click="workOrderServerDescribe">引用服务信息</a-button> -->
                    </template>
                    <a-textarea style="height: 100px;" v-model="form.replyUserText" :maxLength="200" rows="3"
                        placeholder="请输入回复用户内容，不超过200个字" auto-size />
                    <div style="text-align: right;position: absolute;width: 100%;">
                        {{ form.replyUserText.length }}/200
                    </div>
                </a-form-model-item>
                <h3>归档信息</h3>
                <a-form-model-item ref="demandServerType" label="需求类别" required prop="demandServerType">
                    <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="form.demandServerType"
                        placeholder="请选择需求类别">
                        <a-select-option v-for="d in needColumns" :key="d.demandServerType">
                            {{ d.demandServerTypeName }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="需求分类" required prop="Classify">
                    <a-cascader style="width: 100%;" v-model="form.Classify"
                        :getPopupContainer="(triggerNode) => triggerNode.parentNode" :options="options"
                        :fieldNames="fieldNames" placeholder="请选择需求分类" @change="onChange" />
                </a-form-model-item>
                <a-form-model-item ref="isReasonableDemand" label="是否有效需求" required prop="isReasonableDemand">
                    <a-radio-group v-model="form.isReasonableDemand">
                        <a-radio :value="1">
                            是
                        </a-radio>
                        <a-radio :value="0">
                            否
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item ref="solutionInformation" label="实际解决情况" required prop="solutionInformation">
                    <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                        v-model="form.solutionInformation" placeholder="请选择实际解决情况">
                        <a-select-option v-for="d in actualColumns" :key="d.key">
                            {{ d.name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item v-if="form.solutionInformation == '3'" ref="unsolvableText" label="无法解决原因"
                    prop="unsolvableText">
                    <a-textarea style="height: 80px;" v-model="form.unsolvableText" :maxLength="200" rows="3"
                        placeholder="请填写无法解决原因" auto-size />
                    <div style="text-align: right;position: absolute;width: 100%;">
                        {{ form.unsolvableText.length }}/200
                    </div>
                </a-form-model-item>
                <a-form-model-item v-else ref="serverDescribe" label="服务描述" prop="serverDescribe">
                    <a-textarea style="height: 80px;" v-model="form.serverDescribe" :maxLength="200" rows="3"
                        placeholder="请填写服务描述" auto-size />
                    <div style="text-align: right;position: absolute;width: 100%;">
                        {{ form.serverDescribe.length }}/200
                    </div>
                </a-form-model-item>
                <a-form-model-item v-if="form.solutionInformation !== '3'" ref="fileUrlList" label="附件上传"
                    prop="fileUrlList">
                    <a-upload :action="IMG_API + '/oss/files'" list-type="picture-card" :file-list="form.fileUrlList"
                        :beforeUpload="beforeUpload" @preview="handlePreview" :showUploadList="{ showPreviewIcon: true }"
                        @change="handleChangeImg">
                        <div v-if="imgFileList.length < 3">
                            <a-icon type="plus" />
                        </div>
                    </a-upload>
                    <span>
                        支持常用的图片格式与视频格式，附件不超过3个，单个文件大小不超过50M
                    </span>
                    <a-modal :maskStyle="{ padding: '10px' }" :visible="previewVisible" :footer="null"
                        @cancel="handleIMGCancel">
                        <img alt="example" style="width: 100%" :src="previewImage" />
                    </a-modal>
                </a-form-model-item>
                <h3>回访信息</h3>
                <a-form-model-item ref="requireCallback" label="是否需要回访" required prop="requireCallback">
                    <a-radio-group v-model="form.requireCallback">
                        <a-radio :value="1">
                            是
                        </a-radio>
                        <a-radio :value="0">
                            否
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <template v-if="form.requireCallback == 1">
                    <a-form-model-item ref="returnVisitType" label="回访方式" required prop="returnVisitType">
                        <a-radio-group v-model="form.returnVisitType">
                            <a-radio :value="'1'">
                                满意度调查
                            </a-radio>
                            <a-radio :value="'2'">
                                电话回访
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <template v-if="form.returnVisitType == '2'">
                        <a-form-model-item ref="isFinish" label="是否完成回访" required prop="isFinish">
                            <a-radio-group v-model="form.isFinish">
                                <a-radio :value="1">
                                    是
                                </a-radio>
                                <a-radio :value="0">
                                    否
                                </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <template v-if="form.isFinish == '1'">
                            <a-form-model-item ref="isResolved" label="需求是否解决" required prop="isResolved">
                                <a-radio-group v-model="form.isResolved">
                                    <a-radio :value="1">
                                        是
                                    </a-radio>
                                    <a-radio :value="0">
                                        否
                                    </a-radio>
                                </a-radio-group>
                            </a-form-model-item>
                            <a-form-model-item ref="isSatisfaction"
                                label="客户是否满意                                                                                                 "
                                required prop="isSatisfaction">
                                <a-radio-group v-model="form.isSatisfaction">
                                    <a-radio value="1">
                                        是
                                    </a-radio>
                                    <a-radio value="3">
                                        否
                                    </a-radio>
                                </a-radio-group>
                            </a-form-model-item>
                            <a-form-model-item v-if="form.isFinish == 1" ref="returnVisitText" label="其他反馈"
                                prop="returnVisitText">
                                <a-textarea style="height: 100px;" v-model="form.returnVisitText" :maxLength="100" rows="3"
                                    placeholder="如您有其他反馈，请在此处填写" auto-size />
                                <div style="text-align: right;position: absolute;width: 100%;">
                                    {{ form.returnVisitText.length }}/100
                                </div>
                            </a-form-model-item>
                        </template>
                    </template>
                </template>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import * as  api from '@/api/demandPoolShunyi';
import { IMG_API } from "@/config";
import { getclassFiy } from '@/api/demandPoolShunyi';
export default {
    data() {
        return {
            visible: false,
            confirmLoading: false,
            needColumns: [],
            options: [],
            fieldNames: { label: 'classifyName', value: 'classifyId', children: 'childList' },
            demandServiceTypeList: [],
            form: {
                demandServerType: undefined, //需求服务类型
                isReasonableDemand: 1, //是否为有效需求(1:是 0:否)
                solutionInformation: undefined, //实际解决情况
                replyUserText: "", //回复用户内容
                unsolvableText: "", //无法解决原因
                serverDescribe: "", //服务描述(非受限)
                firstsClassify: '',
                secondsClassify: '',
                thirdsClassify: '',
                fileUrlList: [],
                Classify: [],
                returnVisitType: "1",
                isSatisfaction: "1",
                isResolved: 1,
                isFinish: 1,
                returnVisitText: "",
                requireCallback: 1,
            },

            rules: {
                unsolvableText: [{ required: true, message: '请填写无法解决原因', trigger: 'blur' },],
                replyUserText: [{ required: true, message: '请填写回复用户内容', trigger: 'blur' },],
                demandServerType: [{ required: true, message: '请选择需求类别', trigger: 'blur' },],
                Classify: [{ required: true, message: '请选择需求分类', trigger: 'blur' },],
                isReasonableDemand: [{ required: true, message: '请选择是否是有效需求', trigger: 'blur' },],
                solutionInformation: [{ required: true, message: '请选择实际解决情况', trigger: 'blur' },],
                serverDescribe: [{ required: true, message: '请填写服务描述', trigger: 'blur' },],
            },
            actualColumns: [{ name: '实际解决', key: '1' }, { name: '及时回复、响应', key: '2' }, { name: '受限', key: '3' }],
            previewVisible: false,
            imgFileList: [],
            IMG_API,
            previewImage: '',
        }
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        show(val) {
            this.visible = val;
        }
    },

    created() {
        this.innerauditState()
        this.getclassFiy()
    },
    methods: {
        workOrderServerDescribe() {
            console.log('=============');
            api.workOrderServerDescribe({ workOrderId: this.id }).then(res => {
                this.form.replyUserText = res.data
            })
        },
        // 获取需求类别
        innerauditState() {
            api.requirementDemandServertype().then(res => {
                this.needColumns = res.data
            })
        },
        handleOk() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    this.confirmLoading = true;
                    let fileList = [];
                    this.form.fileUrlList.forEach((item) => {
                        if (item.status === 'done') {
                            fileList.push(item.response.redirect_uri);
                        } else {
                        }
                    });
                    let form = {}
                    form.replyUserText = this.form.replyUserText
                    form.demandServerType = this.form.demandServerType
                    form.firstsClassify = this.form.firstsClassify
                    form.secondsClassify = this.form.secondsClassify
                    form.thirdsClassify = this.form.thirdsClassify
                    form.isReasonableDemand = this.form.isReasonableDemand
                    form.solutionInformation = this.form.solutionInformation
                    form.unsolvableText = this.form.unsolvableText //无法解决原因
                    form.serverDescribe = this.form.serverDescribe //服务描述(非受限)
                    form.requireCallback = this.form.requireCallback
                    if (this.form.requireCallback == 1) {
                        form.returnVisitType = this.form.returnVisitType
                        if (this.form.returnVisitType == '2') {
                            form.isFinish = this.form.isFinish
                            if (this.form.isFinish == 1) {
                                form.isResolved = this.form.isResolved
                                form.isSatisfaction = this.form.isSatisfaction
                                form.returnVisitText = this.form.returnVisitText
                            }

                        }
                    }
                    form.fileUrlList = fileList
                    form.workOrderId = this.id
                    console.log(form);
                    const res = await api.userFinishWorkorder(form);
                    console.log(res, '==================');
                    if (res.code == "200") {
                        this.$message.success('提交成功');
                        this.$emit('resetSearchForm')
                        this.handleCancel()
                    } else {
                        this.$message.error(res.msg);
                    }
                }

                this.confirmLoading = false
            });
        },
        handleCancel() {
            this.$emit('update:show', false)
            Object.assign(this.form, this.$options.data().form)
        },
        onChange(value) {
            switch (value.length) {
                case 1:
                    this.form.firstsClassify = value[0]
                    break;
                case 2:
                    this.form.firstsClassify = value[0]
                    this.form.secondsClassify = value[1]
                    break;
                case 3:
                    this.form.firstsClassify = value[0]
                    this.form.secondsClassify = value[1]
                    this.form.thirdsClassify = value[2]
                    break;

                default:
                    break;
            }
        },
        recursion(res) {
            for (let index = 0; index < res.length; index++) {
                const item = res[index];
                if (item.childList.length) {
                    this.recursion(item.childList)
                } else {
                    item.childList = null
                }
            }
        },
        async getclassFiy() {
            const res = await getclassFiy();
            this.recursion(res.data)
            this.options = res.data;
        },
        // 预览图片
        handlePreview(file) {
            this.previewImage = file.url ? file.url : file.response.redirect_uri;
            this.previewVisible = true;
        },
        handleChangeImg(info) {
            this.form.fileUrlList = info.fileList
            this.imgFileList = info.fileList
        },
        handleIMGCancel() {
            this.previewVisible = false;
        },
        // 文件上传前判断类型
        beforeUpload(file) {
            let fileType =
                file.type == "video/mp4" ||
                file.type == "video/quicktime" ||
                file.type == "image/png" ||
                file.type == "image/jpg" ||
                file.type == "image/jpeg";
            if (!fileType) {
                this.$message.error("文件格式不正确");
                return null;
            } else {
                return true;
            }
        },
    }
}
</script>

<style scoped >
.ant-form-item {
    margin-bottom: 0;
}

h3 {
    margin-top: 20px;
}
</style>