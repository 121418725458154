<template>
    <div>
        <a-drawer title="工单审核" placement="right" :closable="true" :visible="visible" @close="onClose" width='600px'>
            <a-form-model ref="ruleForm" :model="form">
                <h3>审核信息</h3>
                <a-form-model-item ref="auditState" label="审核是否通过" required prop="auditState">
                    <a-radio-group v-model="form.auditState">
                        <a-radio value="1">
                            是
                        </a-radio>
                        <a-radio value="0">
                            否
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <h3>回复用户信息</h3>
                <a-form-model-item ref="replyUserText" label="回复用户内容" required prop="replyUserText">
                    <a-input style="position: relative" v-model="form.replyUserText" :maxLength="100" placeholder="请输入服务描述"
                        type="textarea" />
                    <div class="tips">
                        {{ form.replyUserText.length }}/100
                    </div>
                </a-form-model-item>
                <a-form-model-item ref="rejectCause" label="审核驳回原因" required prop="rejectCause">
                    <a-input style="position: relative" v-model="form.rejectCause" :maxLength="200" placeholder="请填写审核驳回的原因"
                        type="textarea" />
                    <div class="tips">
                        {{ form.rejectCause.length }}/200
                    </div>
                </a-form-model-item>
                <h3>归档信息</h3>
                <a-form-model-item ref="demandServerTypeName" label="需求类别" required prop="demandServerTypeName">
                    <a-select v-model="form.demandServerTypeName" placeholder="请选择需求类别">
                        <a-select-option v-for="item in businessColumns" :key="item.demandServerType"
                            :label="item.demandServerType" :value="item.demandServerType">
                            {{ item.demandServerTypeName }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item ref="secondsClassifyText" label="需求分类" required prop="secondsClassifyText">
                    <a-tree-select v-model="form.secondsClassifyText" :replaceFields="replaceFields" style="width: 100%"
                        :tree-data="treeData" :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
                        search-placeholder="请选择需求类别" />
                </a-form-model-item>
                <a-form-model-item ref="isReasonableDemand" label="是否有效信息" required prop="isReasonableDemand">
                    <a-radio-group v-model="form.isReasonableDemand">
                        <a-radio value="1">
                            是
                        </a-radio>
                        <a-radio value="0">
                            否
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item ref="solutionInformation" label="实际解决情况" required prop="solutionInformation">
                    <a-radio-group v-model="form.solutionInformation">
                        <a-radio value="1">
                            实际解决
                        </a-radio>
                        <a-radio value="2">
                            及时回复、响应
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
            </a-form-model>
        </a-drawer>
    </div>
</template>
<script>
import { getclassFiy, getclassFiyType } from '@/api/demandPoolShunyi';
export default {
    name: 'workOrder',
    data() {
        return {
            visible: false,
            form: {
                auditState: '1',
                replyUserText: '',// 回复用户内容
                rejectCause: '', // 驳回原因
                secondsClassifyText: undefined, // 需求分类
                demandServerTypeName: undefined, // 需求类别
                isReasonableDemand: '1', // 是否为有效需求
                solutionInformation: '1', // 实际解决情况
            },
            treeData: [],
            businessColumns: [],
            replaceFields: {
                id: 'classifyId',
                label: 'classifyName',
                children: 'childList',
                value: 'classifyId'
            },
        }
    },
    props: {
        id: {
            type: String,
            default: '06fc950224000000'
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        id: {
            handler(newVal, oldVal) {
            },
            deep: true
        },
        show(val) {
            this.visible = val;
        }
    },
    created() {
        this.getclassFiy();
        this.getclassFiyType();
    },
    methods: {
        onClose() {
            this.$emit('update:show', false)
        },
        async getclassFiy() {
            const res = await getclassFiy();
            res.data.map(item => {
                if (item.childList) {
                    return item.selectable = false
                }
            });
            this.treeData = res.data;
        },
        async getclassFiyType() {
            const res = await getclassFiyType();
            if (res.code == "200") {
                this.businessColumns = res.data;
            }
        },
        onClose() {
            this.$emit('update:show', false)
        },
    },
}
</script>
<style scoped lang="scss">
.tips {
    color: #d9d9d9;
    position: absolute;
    bottom: -40px;
    right: 0;
}

>>>.ant-form-item {
    margin: 0;
}
</style>