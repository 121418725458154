<template>
    <div>
        <a-modal width="800px" :maskClosable="false" title="服务审核" :visible="true" :confirm-loading="confirmLoading"
            @ok="handleOk" @cancel="handleCancel"
            :bodyStyle="{ height: '75vh', overflowY: 'scroll', padding: '20px 120px ' }">
            <a-form-model ref="ruleForm" :rules="rules" :model="form">
                <h3 style="margin-top:0">服务审核</h3>
                <a-form-model-item ref="auditState" label="审核是否通过" required prop="auditState">
                    <a-radio-group v-model="form.auditState">
                        <a-radio :value="1">
                            是
                        </a-radio>
                        <a-radio :value="0">
                            否
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item v-if="form.auditState == '0'" ref="rejectCause" label="审核驳回原因" prop="rejectCause">
                    <a-textarea style="height: 100px;" v-model="form.rejectCause" :maxLength="200" rows="3"
                        placeholder="请输入审核驳回原因，不超过200个字" auto-size />
                    <div style="text-align: right;position: absolute;width: 100%;">
                        {{ form.rejectCause.length }}/200
                    </div>
                </a-form-model-item>
                <template v-if="form.auditState == 1">
                    <a-form-model-item ref="replyUserText" :colon="false" prop="replyUserText">
                        <template #label>
                            <span>服务信息</span>
                            <a-button type="link" @click="workOrderServerDescribe">引用服务信息</a-button>
                        </template>
                        <a-textarea style="height: 100px;" v-model="form.replyUserText" :maxLength="200" rows="3"
                            placeholder="请输入服务信息，不超过200个字" auto-size />
                        <div style="text-align: right;position: absolute;width: 100%;">
                            {{ form.replyUserText.length }}/200
                        </div>
                    </a-form-model-item>
                    <h3>归档信息</h3>
                    <a-form-model-item ref="demandServerType" label="需求服务类型" required prop="demandServerType">
                        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                            v-model="form.demandServerType" placeholder="请选择需求服务类型">
                            <a-select-option v-for="d in demandServiceTypeList" :key="d.demandServerType">
                                {{ d.demandServerTypeName }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="需求分类" prop="Classify">
                        <a-cascader style="width: 100%;" v-model="form.Classify"
                            :getPopupContainer="(triggerNode) => triggerNode.parentNode" :options="options"
                            :fieldNames="fieldNames" placeholder="请选择需求分类" @change="onChange" />
                    </a-form-model-item>
                    <a-form-model-item ref="isReasonableDemand" label="是否有效需求" required prop="isReasonableDemand">
                        <a-radio-group v-model="form.isReasonableDemand">
                            <a-radio :value="1">
                                是
                            </a-radio>
                            <a-radio :value="0">
                                否
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item ref="solutionInformation" label="实际解决情况" required prop="solutionInformation">
                        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                            v-model="form.solutionInformation" placeholder="请选择实际解决情况">
                            <a-select-option v-for="d in actualColumns" :key="d.key">
                                {{ d.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <h3>回访信息</h3>
                    <a-form-model-item ref="requireCallback" label="是否需要回访" required prop="requireCallback">
                        <a-radio-group v-model="form.requireCallback">
                            <a-radio :value="1">
                                是
                            </a-radio>
                            <a-radio :value="0">
                                否
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <template v-if="form.requireCallback == '1'">
                        <a-form-model-item ref="isFinish" label="是否完成回访" required prop="isFinish">
                            <a-radio-group v-model="form.isFinish">
                                <a-radio :value="1">
                                    是
                                </a-radio>
                                <a-radio :value="0">
                                    否
                                </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item v-if="form.isFinish == '1'" ref="isResolved" label="需求是否解决" required
                            prop="isResolved">
                            <a-radio-group v-model="form.isResolved">
                                <a-radio :value="1">
                                    是
                                </a-radio>
                                <a-radio :value="0">
                                    否
                                </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item v-if="form.isFinish == '1'" ref="isSatisfaction"
                            label="客户是否满意" required prop="isSatisfaction">
                            <a-radio-group v-model="form.isSatisfaction">
                                <a-radio :value="1">
                                    是
                                </a-radio>
                                <a-radio :value="3">
                                    否
                                </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item v-if="form.isFinish == 1" ref="returnVisitText" label="其他反馈"
                            prop="returnVisitText">
                            <a-textarea v-model="form.returnVisitText" style="height: 80px;" :maxLength="100" rows="3"
                                placeholder="请输入其他反馈，不超过100个字" auto-size />
                            <div style="text-align: right;position: absolute;width: 100%">
                                {{ form.returnVisitText.length }}/100
                            </div>
                        </a-form-model-item>
                    </template>

                </template>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import moment from 'moment';
import { IMG_API } from "@/config";
import * as  api from '@/api/demandPoolShunyi';
export default {
    data() {
        return {
            IMG_API,
            visible: false,
            confirmLoading: false,
            needColumns: [],
            options: [],
            fieldNames: { label: 'classifyName', value: 'classifyId', children: 'childList' },
            form: {
                rejectCause: '',// 驳回原因
                auditState: 1,
                replyUserText: '',// 回复用内容
                solutionTime: '',// 解决时间
                demandServerType: undefined,// 需求服务类型
                firstsClassify: '',
                secondsClassify: '',
                thirdsClassify: '',
                solutionInformation: undefined,// 实际解决情况
                isReasonableDemand: 1,// 是否有效需求
                isGivePoints: 0,// 是否发放积分
                givePointsType: 1,// 积分发放类型
                givePointsAmount: '',// 发放数量
                isSatisfaction: 1,// 是否满意
                isResolved: 1,// 需求是否解决(1:已解决 0:未解决)'
                isFinish: 1,// 是否完成回访(0:未完成1:已完成)'
                requireCallback: 1,// 是否需要回访(1:需要0:不需要)
                Classify: [],
                returnVisitText: ''
            },
            rules: {
                solutionTime: [{ required: true, message: '请选择需求解决时间', trigger: 'blur' },],
                Classify: [{ required: true, message: '请选择需求分类', trigger: 'blur' },],
                demandServerType: [{ required: true, message: '请选择需求类型', trigger: 'blur' },],
                linkmanPhone: [{ required: true, message: '请填写客户联系方式', trigger: 'blur' },],
                solutionInformation: [{ required: true, message: '请选择实际解决情况', trigger: 'blur' },],
                givePointsAmount: [{ required: true, message: '请填写积分发放数量', trigger: 'blur' },],
                rejectCause: [{ required: true, message: '请填写审核驳回原因', trigger: 'blur' },],
            },
            demandServiceTypeList: [],
            actualColumns: [{ name: '实际解决', key: '1' }, { name: '及时回复、响应', key: '2' }, { name: '受限', key: '3' }],
        }
    },
    props: {
        serverDescribe: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        show(val) {
            this.visible = val;
        }
    },
    created() {
        this.getServertype()
        this.getclassFiy()
    },
    methods: {
        workOrderServerDescribe() {
            console.log('=============');
            api.workOrderServerDescribe({ workOrderId: this.id }).then(res => {
                this.form.replyUserText = res.data
            })
        },
        recursion(res) {
            for (let index = 0; index < res.length; index++) {
                const item = res[index];
                if (item.childList.length) {
                    this.recursion(item.childList)
                } else {
                    console.log(item);
                    item.childList = null
                }
            }
        },
        // 分类
        async getclassFiy() {
            const res = await api.getclassFiy();
            this.recursion(res.data)
            this.options = res.data;
        },
        //需求服务类型
        async getServertype() {
            const res = await api.requirementDemandServertype();
            if (res.code == 200) {
                this.demandServiceTypeList = res.data;
            }
        },
        // 限制时间
        disabledDate(current) {
            // 获取当前日期
            return current > moment().endOf('day')
        },
        disabledTime(current) {
            // 获取当前时间 
            const currentTime = moment();
            return {
                disabledHours: () => this.range(0, 24).splice(currentTime.hour() + 1),
                disabledMinutes: () => this.range(0, 60).splice(currentTime.minute() + 1),
                disabledSeconds: () => this.range(0, 60).splice(currentTime.second() + 1),
            }
        },
        range(start, end) {
            const result = [];
            for (let i = start; i < end; i++) {
                result.push(i);
            }
            return result;
        },
        handleOk() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.confirmLoading = true
                    let form = {};
                    if (this.form.auditState == 0) {
                        form.auditState = this.form.auditState
                        form.rejectCause = this.form.rejectCause
                    } else {
                        form.auditState = this.form.auditState
                        form.replyUserText = this.form.replyUserText
                        console.log(this.form, '========================');
                        // form = this.form
                        let workOrderReturnVisit = {}
                        form.requireCallback = this.form.requireCallback
                        form.demandServerType = this.form.demandServerType
                        form.firstsClassify = this.form.firstsClassify
                        form.secondsClassify = this.form.secondsClassify
                        form.thirdsClassify = this.form.thirdsClassify
                        form.isReasonableDemand = this.form.isReasonableDemand
                        form.solutionInformation = this.form.solutionInformation
                        workOrderReturnVisit.requireCallback = this.form.requireCallback
                        if (this.form.requireCallback == 1) {
                            workOrderReturnVisit.isFinish = this.form.isFinish
                            workOrderReturnVisit.returnVisitType = "2"
                            if (this.form.isFinish == 1) {
                                workOrderReturnVisit.isResolved = this.form.isResolved
                                workOrderReturnVisit.isSatisfaction = this.form.isSatisfaction
                                workOrderReturnVisit.returnVisitText = this.form.returnVisitText
                            }
                        }
                        form.workOrderReturnVisit = workOrderReturnVisit
                    }
                    api.auditWorkorder({ ...form, workOrderId: this.id, }).then(res => {
                        console.log(res, '====');
                        this.confirmLoading = false
                        if (res.code == "200") {
                            this.$message.success('提交成功');
                            this.$emit('resetSearchForm')
                            this.handleCancel()
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                }
            });
        },
        handleCancel() {
            this.$emit('update:show', false)
            Object.assign(this.form, this.$options.data().form)
        },
        onChange(value) {
            console.log(value);
            switch (value.length) {
                case 1:
                    this.form.firstsClassify = value[0]
                    break;
                case 2:
                    this.form.firstsClassify = value[0]
                    this.form.secondsClassify = value[1]
                    break;
                case 3:
                    this.form.firstsClassify = value[0]
                    this.form.secondsClassify = value[1]
                    this.form.thirdsClassify = value[2]
                    break;

                default:
                    break;
            }
        },
    }
}
</script>
<style scoped>
.ant-form-item {
    margin-bottom: 0;
}

h3 {
    margin-top: 20px;
}
</style>
  