<template>
    <div>
        <a-modal width="800px" :maskClosable="false" :bodyStyle="{ height: '75vh', overflowY: 'scroll' }" title="服务审核"
            :visible="true" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
            <a-form-model ref="ruleForm" :rules="rules" :model="form">
                <h3 style="margin-top:0">服务审核</h3>
                <a-form-model-item ref="auditState" label="审核是否通过" required prop="auditState">
                    <a-radio-group v-model="form.auditState">
                        <a-radio :value="1">
                            是
                        </a-radio>
                        <a-radio :value="0">
                            否
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item v-if="form.auditState == '0'" ref="rejectCause" label="审核驳回原因" prop="rejectCause">
                    <a-textarea style="height: 100px;" v-model="form.rejectCause" :maxLength="200" rows="3"
                        placeholder="请输入审核驳回原因，不超过200个字" auto-size />
                    <div style="text-align: right;position: absolute;width: 100%;">
                        {{ form.rejectCause.length }}/200
                    </div>
                </a-form-model-item>
                <template v-if="form.auditState == '1'">
                    <a-form-model-item ref="replyUserText" :colon="false" prop="replyUserText">
                        <template #label>
                            <span>回复用户内容</span>
                            <a-button type="link"  @click="workOrderServerDescribe">引用服务信息</a-button>
                        </template>
                        <a-textarea style="height: 100px;" v-model="form.replyUserText" :maxLength="200" rows="3"
                            placeholder="请输入回复用户内容，不超过200个字" auto-size />
                        <div style="text-align: right;position: absolute;width: 100%;">
                            {{ form.replyUserText.length }}/200
                        </div>
                    </a-form-model-item>
                    <h3>归档信息</h3>
                    <a-form-model-item ref="demandServerType" label="需求类别" required prop="demandServerType">
                        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                            v-model="form.demandServerType" placeholder="请选择需求类别">
                            <a-select-option v-for="d in needColumns" :key="d.demandServerType">
                                {{ d.demandServerTypeName }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="需求分类" required>
                        <a-cascader style="width: 100%;" v-model="form.Classify"
                            :getPopupContainer="(triggerNode) => triggerNode.parentNode" :options="options"
                            :fieldNames="fieldNames" placeholder="请选择需求分类" @change="onChange" />
                    </a-form-model-item>
                    <a-form-model-item ref="isReasonableDemand" label="是否有效需求" required prop="isReasonableDemand">
                        <a-radio-group v-model="form.isReasonableDemand">
                            <a-radio :value="1">
                                是
                            </a-radio>
                            <a-radio :value="0">
                                否
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item ref="solutionInformation" label="实际解决情况" required prop="solutionInformation">
                        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                            v-model="form.solutionInformation" placeholder="请选择实际解决情况">
                            <a-select-option v-for="d in actualColumns" :key="d.key">
                                {{ d.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <h3>回访信息</h3>
                    <a-form-model-item ref="requireCallback" label="是否需要回访" required prop="requireCallback">
                        <a-radio-group v-model="form.requireCallback">
                            <a-radio :value="1">
                                是
                            </a-radio>
                            <a-radio :value="0">
                                否
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <template v-if="form.requireCallback == 1">
                        <a-form-model-item ref="returnVisitType" label="回访方式" required prop="returnVisitType">
                            <a-radio-group v-model="form.returnVisitType">
                                <a-radio :value="'1'">
                                    满意度调查
                                </a-radio>
                                <a-radio :value="'2'">
                                    电话回访
                                </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <template v-if="form.returnVisitType == '2'">
                            <a-form-model-item ref="isFinish" label="是否完成回访" required prop="isFinish">
                                <a-radio-group v-model="form.isFinish">
                                    <a-radio :value="1">
                                        是
                                    </a-radio>
                                    <a-radio :value="0">
                                        否
                                    </a-radio>
                                </a-radio-group>
                            </a-form-model-item>
                            <template v-if="form.isFinish == '1'">
                                <a-form-model-item ref="isResolved" label="需求是否解决" required prop="isResolved">
                                    <a-radio-group v-model="form.isResolved">
                                        <a-radio :value="1">
                                            是
                                        </a-radio>
                                        <a-radio :value="0">
                                            否
                                        </a-radio>
                                    </a-radio-group>
                                </a-form-model-item>
                                <a-form-model-item ref="isSatisfaction"
                                    label="客户是否满意                                                                                                 "
                                    required prop="isSatisfaction">
                                    <a-radio-group v-model="form.isSatisfaction">
                                        <a-radio value="1">
                                            是
                                        </a-radio>
                                        <a-radio value="3">
                                            否
                                        </a-radio>
                                    </a-radio-group>
                                </a-form-model-item>
                                <a-form-model-item ref="returnVisitText" label="其他反馈"
                                    prop="returnVisitText">
                                    <a-textarea style="height: 100px;" v-model="form.returnVisitText" :maxLength="100"
                                        rows="3" placeholder="如您有其他反馈，请在此处填写" auto-size />
                                    <div style="text-align: right;position: absolute;width: 100%;">
                                        {{ form.returnVisitText.length }}/100
                                    </div>
                                </a-form-model-item>
                            </template>
                        </template>
                    </template>

                </template>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import * as  api from '@/api/demandPoolShunyi';
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: ''
        },
        serverDescribe: {
            type: String,
            default: ''
        },
    },
    watch: {
        show(val) {
            this.visible = val;
        }
    },
    data() {
        return {
            visible: false,
            confirmLoading: false,
            fieldNames: { label: 'classifyName', value: 'classifyId', children: 'childList' },
            form: {
                rejectCause: '',// 审核驳回原因
                auditState: 1,// 审核是否通过
                replyUserText: "", //回复用户内容
                demandServerType: undefined,// 需求类别
                firstsClassify: '',
                secondsClassify: '',
                thirdsClassify: '',
                solutionInformation: '',// 实际解决情况
                isReasonableDemand: 1,// 是否有效需求
                Classify: [],
                returnVisitType: "1",
                isSatisfaction: "1",
                isResolved: 1,
                isFinish: 1,
                returnVisitText: "",
                requireCallback: 1,
            },
            rules: {
                rejectCause: [{ required: true, message: '请填写审核驳回原因', trigger: 'blur' },],
                replyUserText: [{ required: true, message: '请填写回复用户内容', trigger: 'blur' },],
                demandServerType: [{ required: true, message: '请选择需求类别', trigger: 'blur' },],
                secondsClassify: [{ required: true, message: '请选择需求分类', trigger: 'blur' },],
                isReasonableDemand: [{ required: true, message: '请选择是否是有效需求', trigger: 'blur' },],
                solutionInformation: [{ required: true, message: '请选择实际解决情况', trigger: 'blur' },],
            },
            options: [],
            enterpriseList: [],// 企业列表
            needColumns: [], //需求类型下拉值
            actualColumns: [{ name: '实际解决', key: '1' }, { name: '及时回复、响应', key: '2' }, { name: '受限', key: '3' }],
        };
    },
    created() {
        this.innerauditState()
        this.getclassFiy()
    },
    methods: {
        workOrderServerDescribe() {
            console.log('=============');
            api.workOrderServerDescribe({ workOrderId: this.id }).then(res => {
                this.form.replyUserText = res.data
            })
        },
        // 获取需求类别
        innerauditState() {
            api.requirementDemandServertype().then(res => {
                this.needColumns = res.data
            })
        },
        handleOk(e) {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.confirmLoading = true;
                    let form = {}
                    form.auditState = this.form.auditState
                    if (form.auditState == 1) {
                        form.replyUserText = this.form.replyUserText
                        form.demandServerType = this.form.demandServerType
                        form.firstsClassify = this.form.firstsClassify
                        form.secondsClassify = this.form.secondsClassify
                        form.thirdsClassify = this.form.thirdsClassify
                        form.isReasonableDemand = this.form.isReasonableDemand
                        form.solutionInformation = this.form.solutionInformation     
                        // 回访信息
                        let workOrderReturnVisit = {}
                        form.requireCallback = this.form.requireCallback
                        workOrderReturnVisit.requireCallback = this.form.requireCallback
                   
                        if (this.form.requireCallback == 1) {
                            workOrderReturnVisit.returnVisitType = this.form.returnVisitType
                            if (this.form.returnVisitType == '2') {
                                workOrderReturnVisit.isFinish = this.form.isFinish
                                if (this.form.isFinish == 1) {
                                    workOrderReturnVisit.isResolved = this.form.isResolved
                                    workOrderReturnVisit.isSatisfaction = this.form.isSatisfaction
                                    workOrderReturnVisit.returnVisitText = this.form.returnVisitText
                                }

                            }
                        }
                        form.workOrderReturnVisit =workOrderReturnVisit
                    } else {
                        form.rejectCause = this.form.rejectCause
                    }
                    console.log(form);
                    api.auditWorkorder({ ...form, workOrderId: this.id }).then(res => {
                        if (res.code == "200") {
                            this.$message.success("提交成功");
                            this.$emit('resetSearchForm')
                            this.handleCancel()
                        } else {
                            this.$message.error(res.msg);
                        }
                        this.confirmLoading = false
                    })
                }
            })

        },
        onChange(value) {
            switch (value.length) {
                case 1:
                    this.form.firstsClassify = value[0]
                    break;
                case 2:
                    this.form.firstsClassify = value[0]
                    this.form.secondsClassify = value[1]
                    break;
                case 3:
                    this.form.firstsClassify = value[0]
                    this.form.secondsClassify = value[1]
                    this.form.thirdsClassify = value[2]
                    break;

                default:
                    break;
            }
        },
        handleCancel(e) {
            this.$emit('update:show', false)
            this.confirmLoading = true;
            Object.assign(this.form, this.$options.data().form)
        },
        recursion(res) {
            for (let index = 0; index < res.length; index++) {
                const item = res[index];
                if (item.childList.length) {
                    this.recursion(item.childList)
                } else {
                    item.childList = null
                }
            }
        },
        async getclassFiy() {
            const res = await api.getclassFiy();
            this.recursion(res.data)
            this.options = res.data;
        },
    },
};
</script>
<style scoped >
.ant-form-item {
    margin-bottom: 0;
}

h3 {
    margin-top: 20px;
}
</style>