<template>
    <div>
        <a-modal width="800px" :maskClosable="false" title="创建工单" :visible="true" :confirm-loading="confirmLoading"
            @ok="handleOk" @cancel="showConfirm"
            :bodyStyle="{ height: '75vh', overflowY: 'scroll', padding: '20px 120px ' }">
            <a-form-model ref="ruleForm" :rules="rules" :model="form">
                <h3 style="margin-top:0">工单内容</h3>
                <a-form-model-item ref="demandSource" label="需求来源" required prop="demandSource">
                    <a-radio-group v-model="form.demandSource" @change="handleDemandSourceChange">
                        <a-radio :value="3">
                            400热线
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item ref="startingTime" label="需求提出时间" prop="startingTime">
                    <div style="display: flex;align-items: center;">
                        <a-date-picker :disabled-date="disabledDate"
                        v-model="form.startingDate"
                        style="margin-right: 20px;"
                        :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                        placeholder="请选择需求提出时间" format="YYYY-MM-DD"/>
                        <span style="display: none;">{{ startingTime }}</span>
                        <a-time-picker :disabled="disabledhoiceTime" @openChange ="openChange2" v-model="form.startingH" :disabledHours="disabledStartHours" :disabled-minutes="disabledStartMinutes"
                        :getPopupContainer="(triggerNode) => triggerNode.parentNode" format="HH:mm" />
                    </div>
                </a-form-model-item>
                <a-form-model-item v-if="form.demandSource == '4'" ref="enterpriseProjectId" label="需求所在项目" required
                    prop="enterpriseProjectId">
                    <a-select @change="changeProject" :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                        v-model="form.enterpriseProjectId" placeholder="请选择所属项目">
                        <a-select-option :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                            v-for="item in projectMsgList" :key="item.projectId" :value="item.projectId">
                            {{ item.projectName }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item ref="demandDescribe" label="需求描述" prop="demandDescribe">
                    <a-textarea v-model="form.demandDescribe" style="height: 80px;" :maxLength="100" rows="3"
                        placeholder="请输入需求描述，不超过100个字" auto-size />
                    <div style="text-align: right;position: absolute;width: 100%">
                        {{ form.demandDescribe.length }}/100
                    </div>
                </a-form-model-item>
                <a-form-model-item ref="fileUrlList" label="附件上传" prop="fileUrlList">
                    <a-upload :action="IMG_API + '/oss/files'" list-type="picture-card" :file-list="form.fileUrlList"
                        :beforeUpload="beforeUpload" @preview="handlePreview"
                        :showUploadList="{ showPreviewIcon: true }" @change="handleChangeImg">
                        <div v-if="form.fileUrlList.length < 3">
                            <a-icon type="plus" />
                        </div>
                    </a-upload>
                    <span>
                        支持常用的图片格式与视频格式，附件不超过3个，单个文件大小不超过50M
                    </span>
                    <a-modal :maskStyle="{ padding: '10px' }" :visible="previewVisible" :footer="null"
                        @cancel="handleIMGCancel">
                        <img alt="example" style="width: 100%" :src="previewImage" />
                    </a-modal>
                </a-form-model-item>
                <template v-if="form.demandSource == '3'">
                    <h3>客户信息</h3>
                    <a-form-model-item ref="linkmanPhone" label="客户联系方式" prop="linkmanPhone">
                        <a-input placeholder="请输入联系方式" v-model="form.linkmanPhone" :maxLength="11" />
                    </a-form-model-item>
                    <a-form-model-item ref="enterpriseProjectId" label="客户所在项目" required prop="enterpriseProjectId">
                        <a-select @change="changeProject" :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                            v-model="form.enterpriseProjectId" placeholder="请选择所属项目">
                            <a-select-option v-for="item in projectMsgList" :key="item.projectId"
                                :value="item.projectId">
                                {{ item.projectName }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item v-if="form.enterpriseProjectId &&form.enterpriseProjectId !='社区用户'" ref="enterpriseId" label="客户所在企业"
                        prop="enterpriseId">
                        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" show-search
                            v-model="form.enterpriseId" placeholder="请输入客户所在企业以选择" :default-active-first-option="false"
                            :show-arrow="false" :filter-option="false" :not-found-content="null" @search="onSearch"
                            @change="changeEnterprise">
                            <a-select-option v-for="d in enterpriseList" :key="d.enterpriseId">
                                {{ d.enterpriseName }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item v-if="form.enterpriseId ||form.enterpriseProjectId =='社区用户'" ref="linkmanName" label="客户姓名" prop="linkmanName">
                        <a-select mode="tags" show-search v-model="form.linkmanName" placeholder="请输入客户姓名以选择"
                            :default-active-first-option="false" :show-arrow="false" :filter-option="false"
                            :not-found-content="null" :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                            @search="onSearchName" @change="Changename">
                            <a-select-option v-for="d in persionList" :key="d.id">
                                {{ d.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item ref="appBindPhone" label="客户app绑定手机号" prop="appBindPhone">
                        <a-input placeholder="请输入联系方式" v-model="form.appBindPhone" :maxLength="11" />
                    </a-form-model-item>
                </template>
                <h3>工单分派</h3>
                <a-form-model-item ref="waysOfResolution" label="解决方式" required prop="waysOfResolution">
                    <a-radio-group v-model="form.waysOfResolution">
                        <a-radio :value="1">
                            自行解决
                        </a-radio>
                        <a-radio :value="2">
                            分派解决
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item v-if="form.waysOfResolution == 2" required ref="principalId" label="服务负责人"
                    prop="principalId">
                    <a-tree-select :replaceFields="{
            title: 'organizationName',
            key: 'organizationId',
            children: 'childrenList',
            value: 'organizationId'
        }" :filterTreeNode="false" show-search :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
                        v-model="form.principalId" :maxTagCount="1" placeholder="请输入负责人姓名或手机号以选择"
                        :getPopupContainer="(triggerNode) => triggerNode.parentNode" :tree-data="treeData"
                        @search="onSearchUserName" @change='changePrincipalId'>
                    </a-tree-select>

                </a-form-model-item>
                <a-form-model-item v-if="form.waysOfResolution == 1 && form.demandSource == '3'" ref="innerIsResolved"
                    label="是否解决" required prop="innerIsResolved">
                    <a-radio-group v-model="form.innerIsResolved">
                        <a-radio :value="1">
                            是
                        </a-radio>
                        <a-radio :value="0">
                            否
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>

                <template
                    v-if="form.demandSource == '4' || (form.demandSource == '3' && form.waysOfResolution == 1 && form.innerIsResolved == '1')">
                    <h3>服务内容</h3>
                    <a-form-model-item ref="solutionTime" label="需求解决时间" prop="solutionTime">
                        <div style="display: flex;align-items: center;">
                            <span style="display: none;">{{ solutionTime }}</span>
                            <a-date-picker :disabled="isstartingTime" :disabled-date="disabledDate1"
                             style="margin-right: 20px;"
                            :getCalendarContainer="(triggerNode) => triggerNode.parentNode" v-model="form.solutionDate"
                            format="YYYY-MM-DD" :placeholder="isstartingTime ? '请先选择需求开始时间' : '请选择需求解决时间'" />
                            <a-time-picker :disabled="isstartingTime&&disabledchoiceSolutionTime"
                             v-model="form.solutionH"
                             @openChange ="openChange"
                            :getPopupContainer="(triggerNode) => triggerNode.parentNode" format="HH:mm" />
                        </div>
                        </a-form-model-item>
                    <a-form-model-item ref="serverDescribe" label="服务描述" prop="serverDescribe">
                        <a-textarea v-model="form.serverDescribe" style="height: 80px;" :maxLength="200" rows="3"
                            placeholder="请输入服务描述，不超过200个字" auto-size />
                        <div style="text-align: right;position: absolute;width: 100%">
                            {{ form.serverDescribe.length }}/200
                        </div>
                    </a-form-model-item>
                    <h3>归档信息</h3>
                    <a-form-model-item ref="demandServerType" label="需求服务类型" required prop="demandServerType">
                        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                            v-model="form.demandServerType" @change="changeDemandServerType" placeholder="请选择需求服务类型">
                            <a-select-option v-for="d in demandServiceTypeList" :key="d.demandServerType">
                                {{ d.demandServerTypeName }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="需求分类" prop="Classify">
                        <a-cascader v-model="form.Classify" style="width: 100%;"
                            :getPopupContainer="(triggerNode) => triggerNode.parentNode" :options="options"
                            :fieldNames="fieldNames" placeholder="请选择需求分类" @change="onChange" />
                    </a-form-model-item>

                    <a-form-model-item ref="isReasonableDemand" label="是否有效需求" required prop="isReasonableDemand">
                        <a-radio-group v-model="form.isReasonableDemand">
                            <a-radio :value="1">
                                是
                            </a-radio>
                            <a-radio :value="2">
                                否
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item ref="solutionInformation" label="实际解决情况" required prop="solutionInformation">
                        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                            v-model="form.solutionInformation" @change="changeSolutionInformation
            " placeholder="请选择实际解决情况">
                            <a-select-option v-for="d in actualColumns" :key="d.key">
                                {{ d.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item v-if="form.demandSource == '4'&&form.enterpriseProjectId" ref="servicePlace" label="物业服务地点"
                        prop="servicePlace">
                        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                            v-model="form.servicePlace" placeholder="请选择物业服务地点" @change="changeServicePlace">
                            <a-select-option v-for="d in placeDownloadList" :key="d.placeId">
                                {{ d.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item v-if="form.demandSource == '4'&&form.enterpriseProjectId" ref="serviceMerchant" label="商业服务商户"
                        prop="serviceMerchant">
                        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                            v-model="form.serviceMerchant" placeholder="请选择户商业服务商" @change="changeServiceMerchant">
                            <a-select-option v-for="d in tenantDownloadList" :key="d.commercialTenantId">
                                {{ d.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <!-- <a-form-model-item v-if="form.solutionInformation == '3'" ref="unsolvableText" label="受限原因" required
                        prop="unsolvableText">
                        <a-textarea v-model="form.unsolvableText" style="height: 80px;" :maxLength="200" rows="3"
                            placeholder="请输入受限原因，不超过200个字" auto-size />
                        <div style="text-align: right;position: absolute;width: 100%">
                            {{ form.unsolvableText.length }}/100
                        </div>
                    </a-form-model-item> -->

                    <h3>回访信息</h3>
                    <a-form-model-item ref="requireCallback" label="是否需要回访" required prop="requireCallback">
                        <a-radio-group v-model="form.requireCallback">
                            <a-radio :value="1">
                                是
                            </a-radio>
                            <a-radio :value="0">
                                否
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>

                    <template v-if="form.requireCallback == '1'">
                        <a-form-model-item ref="isFinish" label="是否完成回访" required prop="isFinish">
                            <a-radio-group v-model="form.isFinish">
                                <a-radio :value="1">
                                    是
                                </a-radio>
                                <a-radio :value="0">
                                    否
                                </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item v-if="form.isFinish == '1'" ref="isResolved" label="需求是否解决" required
                            prop="isResolved">
                            <a-radio-group v-model="form.isResolved">
                                <a-radio :value="1">
                                    是
                                </a-radio>
                                <a-radio :value="0">
                                    否
                                </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item v-if="form.isFinish == '1'" ref="isSatisfaction"
                            label="客户是否满意                                                                                                 "
                            required prop="isSatisfaction">
                            <a-radio-group v-model="form.isSatisfaction">
                                <a-radio :value="1">
                                    是
                                </a-radio>
                                <a-radio :value="3">
                                    否
                                </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item v-if="form.isFinish == 1" ref="returnVisitText" label="其他反馈"
                            prop="returnVisitText">
                            <a-textarea v-model="form.returnVisitText" style="height: 80px;" :maxLength="100" rows="3"
                                placeholder="请输入其他反馈，不超过100个字" auto-size />
                            <div style="text-align: right;position: absolute;width: 100%">
                                {{ form.returnVisitText.length }}/100
                            </div>
                        </a-form-model-item>
                    </template>

                </template>
            </a-form-model>
            <a-modal title="保存草稿" :visible="showVisble" @cancel="showVisble = fales">
                <p>确定要保存为草稿吗？</p>

                <template slot="footer">
                    <a-button key="back" @click="handleCancelText">
                        取消
                    </a-button>
                    <a-button key="submit" type="primary" @click="handleOkText">
                        确定
                    </a-button>
                </template>
            </a-modal>
        </a-modal>
    </div>
</template>

<script>
import moment from 'moment';
import { IMG_API } from "@/config";
import * as  api from '@/api/demandPoolShunyi';
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        show(val) {
            this.visible = val;
        },
        'form.solutionTime'() {
           
        },
        // 'form.startingTime'() {
        //     if(this.form.solutionTime){
        //         if(moment(this.form.solutionTime).isAfter(this.form.startingTime)){
        //             console.log('结束时间大于开始时间');
        //         }else{
        //             // this.form.solutionTime = ''
        //             // this.form.solutionDate = ""
        //             // this.form.solutionH = ""
        //         }
        //     }
        // },
    },
    computed: {
        isstartingTime() {
            return !this.form.startingTime ? true : false
        },
        startingTime(){
            if (this.form.startingDate && this.form.startingH) {
                this.form.startingTime = moment(this.form.startingDate).format("YYYY-MM-DD") +" " +moment(this.form.startingH).format("HH:mm")
                this.$refs.ruleForm.validateField('startingTime')
            }else{
                this.form.startingTime = ""
            }
        },
        disabledhoiceTime(){
            if(this.form.startingDate){
                return false
            }else{
                this.form.startingH = ""
                return true
            }
        },
        // 解决时间是否可选择
        disabledchoiceSolutionTime(){
            if(this.form.solutionDate){
                return false
            }else{
                this.form.solutionH = ""
                return true
            }
        },
        solutionTime(){
            if (this.form.solutionDate && this.form.solutionH) {
                this.form.solutionTime = moment(this.form.solutionDate).format("YYYY-MM-DD") +" " + moment(this.form.solutionH).format("HH:mm")
                this.$refs.ruleForm.validateField('solutionTime')
                if(moment(this.form.solutionTime).isSame(this.form.startingTime)){
                       return
                } 
                if(moment(this.form.solutionTime).isAfter(this.form.startingTime)){
                    console.log('结束时间大于开始时间');
                }else{
                    this.$message.error('您填写的需求解决时间早于需求提出时间，请确认后重新输入')
                    this.form.solutionTime = ''
                    this.form.solutionH = ""
                }
                if(moment(this.form.solutionTime).isAfter(moment())){
                    this.$message.error('您填写的需求解决时间晚于当前时间，请确认后重新输入')
                    this.form.solutionTime = ''
                    this.form.solutionH = ""
                }

            }else{
                this.form.solutionTime = ""
            }
        },
    },
    data() {
        return {
            IMG_API,
            visible: false,
            showVisble: false,
            confirmLoading: false,
            form: {
                demandSource: 3,
                startingTime: '',// 开始时间
                enterpriseProjectId: undefined,// 项目id
                demandDescribe: '',
                fileUrlList: [],
                linkmanPhone: '',// 联系方式
                appBindPhone: '',//APP绑定手机号
                enterpriseId: undefined,// 企业id
                linkmanName: undefined,//客户姓名
                waysOfResolution: 1,// 解决方式
                principalId: undefined,// 服务负责人id
                solutionTime: '',// 解决时间
                serverDescribe: '',// 服务描述
                replyUserText: '',// 回复用户内容
                innerIsResolved: 1,// 是否解决
                isResolved: 1,// 需求是否解决
                demandServerType: undefined,// 服务类型id
                isReasonableDemand: 1,// 是否有效需求
                solutionInformation: undefined, //实际解决情况 ( 1:实际解决 2:及时回复、响应）
                servicePlace: undefined,// 物业服务地点
                serviceMerchant: undefined,// 商业服务商户
                firstsClassify: '',
                secondsClassify: '',
                thirdsClassify: '',
                isGivePoints: 0,// 积分是否发放
                givePointsType: 1,// 积分发放类型
                givePointsAmount: '',// 积分发放数量
                requireCallback: 1,// 是否需要回访
                isFinish: 1,// 是否完成回访(0:未完成1:已完成)'
                isSatisfaction: 1,// 客户是否满意
                demandType: '12', // 需求来源类型 12	400热线 13i友圈
                unsolvableText: '',// 受限原因
                Classify: [],
                returnVisitText: '',//其他
                fieldValue: '',// app需求分类展示
                principalIdData: '',// app服务负责人展示
                enterpriseProjectName: '',// app项目展示
                enterpriseIdList: '',// app企业公司展示
                servicePlacetext: '',// app服务地点回显
                serviceMerchanttext: '',// app服务商户回显
                solutionInformationText: '',// app实际解决情况回显
                demandServerName: '',// app服务类型回显
                startingH:'',
                startingDate:'',
                solutionDate:'',
                solutionH:'',
            },
            rules: {
                principalId: [{ required: true, message: '请选择服务负责人', trigger: 'blur' },],
                solutionTime: [{ required: true, message: '请选择需求解决时间', trigger: ['blur','change'] },],
                startingTime: [{ required: true, message: '请选择需求提出时间', trigger: ['blur','change'] },],
                demandDescribe: [{ required: true, message: '请填写需求描述', trigger: 'blur' },],
                replyUserText: [{ required: true, message: '请填写回复用户内容', trigger: 'blur' },],
                serverDescribe: [{ required: true, message: '请填写服务描述', trigger: 'blur' },],
                unsolvableText: [{ required: true, message: '请填写受限原因', trigger: 'blur' },],
                linkmanPhone: [{ required: true, message: '请填写客户联系方式', trigger: 'blur' },],
                enterpriseProjectId: [{ required: true, message: '请填写客户所在项目', trigger: 'blur' },],
                givePointsAmount: [{ required: true, message: '请填写积分发放数量', trigger: 'blur' },],
                // servicePlace: [{ required: true, message: '请选择物业服务地址', trigger: 'blur' },],
                // serviceMerchant: [{ required: true, message: '请选择商业服务商户', trigger: 'blur' },],
                solutionInformation: [{ required: true, message: '请选择实际解决情况', trigger: 'blur' },],
                Classify: [{ required: true, message: '请选择需求分类', trigger: 'blur' },],
                demandServerType: [{ required: true, message: '请选择需求服务类型', trigger: 'blur' },],
            },
            options: [],
            fieldNames: { label: 'classifyName', value: 'classifyId', children: 'childList' },
            demandServiceTypeList: [],
            projectMsgList: [], //所在项目下拉值
            persionList: [], // 客户姓名
            imgFileList: [],
            enterpriseList: [],// 企业列表
            needColumns: [], //需求类型下拉值
            previewImage: '',// 预览图片
            actualColumns: [{ name: '实际解决', key: '1' }, { name: '及时回复、响应', key: '2' }, { name: '受限', key: '3' }],
            previewVisible: false,
            userList: [],// 服务负责人
            placeDownloadList: [],
            tenantDownloadList: [],
            treeData: [],
        };
    },
    created() {
        this.getProject()
        this.getclassFiy()
        this.getServertype()
        // this.placeDownload()
        // this.tenantDownload()
        this.onSearchUserName()
        api.getWorkOrderDraft({ source: 'web',projectId:''  }).then(res => {
            if (res.data) {
                this.form = JSON.parse(res.data)
                if(this.form.startingTime){
                    this.form.startingDate = moment(moment(this.form.startingTime).format("YYYY-MM-DD"))  
                    this.form.startingH = moment(moment(this.form.startingTime).format("HH:mm"),"HH:mm")
                }
                if(this.form.solutionTime){
                    this.form.solutionDate = moment(moment(this.form.solutionTime).format("YYYY-MM-DD"))  
                    this.form.solutionH = moment(moment(this.form.solutionTime).format("HH:mm"),"HH:mm")
                }
                if (this.form.enterpriseProjectId) {
                    this.onSearch()
                    this.placeDownload(this.form.enterpriseProjectId)
                    this.tenantDownload(this.form.enterpriseProjectId)
                }
            }
            console.log(JSON.parse(res.data));
        }).catch(err => {
            console.log(err, '草稿');
        })
    },
    methods: {
        //获取项目信息
        //获取项目信息
        async getProject() {
            // const res = await api.requirementProject2();
            const res = await api.get400ProjectList();
            if (res.code == 200) {
                this.projectMsgList = res.data;
            }
        },
        recursion(res) {
            for (let index = 0; index < res.length; index++) {
                const item = res[index];
                if (item.childList.length) {
                    this.recursion(item.childList)
                } else {
                    item.childList = null
                }
            }
        },
        // 分类
        async getclassFiy() {
            const res = await api.getclassFiy();
            this.recursion(res.data)
            this.options = res.data;
        },
        disabledDate(current) {
            // 获取当前日期
            return current > moment().endOf('day')
        },

        disabledTime(current) {
            // 获取当前时间 
            const currentTime = moment();
            if (!this.form.startingTime) {
                return {
                    disabledHours: () => this.range(0, 24).splice(currentTime.hour() + 1),
                    disabledMinutes: () => this.range(0, 60).splice(currentTime.minute() + 1),
                }
            } else if (this.form.startingTime.format("YYYY-MM-DD") === currentTime.format("YYYY-MM-DD")) {
                if (this.form.startingTime.hour() == currentTime.hour()) {
                    return {
                        disabledHours: () => this.range(0, 24).splice(currentTime.hour() + 1),
                        disabledMinutes: () => this.range(0, 60).splice(currentTime.minute() + 1),
                    }
                } else {
                    return {
                        disabledHours: () => this.range(0, 24).splice(currentTime.hour() + 1),
                    }
                }

            } else {

            }

        },
        disabledDate1(current) {
            // 获取当前日期
            return current > moment().endOf('day') || current < moment(this.form.startingTime ? moment(this.form.startingTime).format("YYYY-MM-DD") : '');
        },

        disabledTime1(current) {
            // 获取当前时间
            const currentTime = moment();
            let str = this.form.startingTime
            if (!this.form.solutionTime) {
                if(moment(str).format("YYYY-MM-DD") == currentTime.format("YYYY-MM-DD")){
                    return {
                    disabledHours: () => {
                        let a = this.range(0, 24).splice(0, moment(str).hour())
                        let b = this.range(0, 24).splice(currentTime.hour() + 1)
                        return [...a, ...b]
                    },
                    disabledMinutes: () => {
                        if ( currentTime.hour() == moment(str).hour()) {
                            let a = this.range(0, 60).splice(0,moment(str).minute())
                            let b = this.range(0, 60).splice(currentTime.minute() + 1)
                            return [...a, ...b]
                        } else {
                            console.log('显示完整时就按')
                            return this.range(0, 60).splice(currentTime.minute() + 1)
                        }
                    },
                }
                }
                return {
                    disabledHours: () => this.range(0, 24).splice(currentTime.hour() + 1),
                    disabledMinutes: () => this.range(0, 60).splice(currentTime.minute() + 1),
                    disabledSeconds: () => this.range(0, 60).splice(currentTime.second() + 1),
                };
            }
            if (this.form.solutionTime.format("YYYY-MM-DD") === moment(str).format("YYYY-MM-DD") && moment(str).format("YYYY-MM-DD") == currentTime.format("YYYY-MM-DD")) {
                return {
                    disabledHours: () => {
                        let a = this.range(0, 24).splice(0, moment(str).hour())
                        let b = this.range(0, 24).splice(currentTime.hour() + 1)
                        return [...a, ...b]
                    },
                    disabledMinutes: () => {
                        console.log(this.form.solutionTime.hour(), currentTime.hour(), moment(str).hour())
                        if (this.form.solutionTime.hour() == currentTime.hour() && this.form.solutionTime.hour() == moment(str).hour()) {
                            console.log('开始时间 等于结束时间,结束时间等于当前时间=========》', moment(str).hour());
                            let a = this.range(0, 60).splice(currentTime.minute() + 1)
                            let b = this.range(0, 60).splice(currentTime.minute() + 1)
                            return [...a, ...b]
                        } else if (this.form.solutionTime.hour() == moment(str).hour() && this.form.solutionTime.hour() != currentTime.hour()) {
                            console.log('开始时间 等于结束时间,结束时间不等于当前时间=========》', moment(str).hour());
                            let a = this.range(0, 60).splice(0, moment(str).minute())
                            return a
                        } else if (this.form.solutionTime.hour() != moment(str).hour() && this.form.solutionTime.hour() == currentTime.hour()) {
                            console.log('开始时间 不等于结束时间,结束时间等于当前时间=========》', moment(str).hour());
                            return this.range(0, 60).splice(currentTime.minute() + 1)
                        } else {
                            console.log('显示完整时就按')
                            return []
                        }

                    },
                }
            } else if (this.form.solutionTime.format("YYYY-MM-DD") === moment(str).format("YYYY-MM-DD") && moment(str).format("YYYY-MM-DD") !== currentTime.format("YYYY-MM-DD")) {
                return {
                    disabledHours: () => {
                        let a = this.range(0, 24).splice(0, moment(str).hour())
                        return a
                    },
                    disabledMinutes: () => {
                        console.log(this.form.solutionTime.hour() , currentTime.hour(), moment(str).hour());
                        if(this.form.solutionTime.hour() == currentTime.hour() && this.form.solutionTime.hour() == moment(str).hour()){
                            console.log('===++++++++++++++++++');
                            let a = this.range(0, 60).splice(0, moment(str).minute())
                            return [...a]
                        }else {
                            return []
                        }
                    },
                }
            }
            else if (this.form.solutionTime.format("YYYY-MM-DD") !== currentTime.format("YYYY-MM-DD")) {

            }
            else {
                return {
                    disabledHours: () => this.range(0, 24).splice(currentTime.hour() + 1),
                    disabledMinutes: () => this.range(0, 60).splice(currentTime.minute() + 1),
                    disabledSeconds: () => this.range(0, 60).splice(currentTime.second() + 1),
                };
            }
        },
        range(start, end) {
            const result = [];
            for (let i = start; i < end; i++) {
                result.push(i);
            }
            return result;
        },
        //需求服务类型
        async getServertype() {
            const res = await api.requirementDemandServertype();
            if (res.code == 200) {
                this.demandServiceTypeList = res.data;
            }
        },
        // 预览图片
        handlePreview(file) {
            this.previewImage = file.url ? file.url : file.response.redirect_uri;
            this.previewVisible = true;
        },
        handleChangeImg(info) {
            this.form.fileUrlList = info.fileList
            this.imgFileList = info.fileList
            console.log(info.fileList);
        },
        // 文件上传前判断类型
        beforeUpload(file) {
            console.log(file);
            let fileType =
                file.type == "video/mp4" ||
                file.type == "video/quicktime" ||
                file.type == "image/png" ||
                file.type == "image/jpg" ||
                file.type == "image/jpeg";
            if (!fileType) {
                this.$message.error("文件格式不正确");
                return null;
            } else {
                return true;
            }
        },
        onChange(value, selectedOptions) {
            console.log(selectedOptions);
            switch (value.length) {
                case 1:
                    this.form.firstsClassify = value[0]
                    break;
                case 2:
                    this.form.firstsClassify = value[0]
                    this.form.secondsClassify = value[1]
                    break;
                case 3:
                    this.form.firstsClassify = value[0]
                    this.form.secondsClassify = value[1]
                    this.form.thirdsClassify = value[2]
                    break;

                default:
                    break;
            }
            this.form.fieldValue = selectedOptions.map((option) => option.classifyName).join('/');
        },
        async handleOk(e) {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    var ifStatus = true;
                    let fileList = [];
                    this.form.fileUrlList.forEach((item) => {
                        if (item.status === 'done') {
                            fileList.push(item.url || item.response.redirect_uri);
                        } else {
                            ifStatus = false
                        }
                    });
                    if (!ifStatus) {
                        this.$message.error('文件上传中');
                        return false
                    }
                    this.confirmLoading = true;
                    let form = {};
                    if (this.form.waysOfResolution == "2") {
                        form.principalId = this.form.principalId;
                    }
                    form.demandType = this.form.demandType
                    form.fileUrlList = fileList
                    form.demandSource = this.form.demandSource
                    form.startingTime = this.form.startingTime
                    form.waysOfResolution = this.form.waysOfResolution
                    form.demandDescribe = this.form.demandDescribe
                    form.enterpriseProjectId = this.form.enterpriseProjectId
                    if (this.form.demandSource == '3') {
                        form.linkmanPhone = this.form.linkmanPhone
                        form.enterpriseId = this.form.enterpriseId
                        form.linkmanName = this.form.linkmanName
                        form.appBindPhone = this.form.appBindPhone
                    }
                    if (this.form.waysOfResolution == '1' && this.form.demandSource == '3') {
                        form.innerIsResolved = this.form.innerIsResolved
                    }
                    if (this.form.demandSource == '4' || (this.form.demandSource == '3' && this.form.waysOfResolution == '1' && this.form.innerIsResolved == '1')) {
                        form.solutionTime = this.form.solutionTime ? moment(this.form.solutionTime).format("YYYY-MM-DD HH:mm") : '';
                        form.serverDescribe = this.form.serverDescribe
                        form.demandServerType = this.form.demandServerType
                        form.firstsClassify = this.form.firstsClassify
                        form.secondsClassify = this.form.secondsClassify
                        form.thirdsClassify = this.form.thirdsClassify
                        form.isReasonableDemand = this.form.isReasonableDemand
                        form.solutionInformation = this.form.solutionInformation
                        form.servicePlace = this.form.servicePlace
                        form.serviceMerchant = this.form.serviceMerchant
                        form.requireCallback = this.form.requireCallback
                        if (form.requireCallback == 1) {
                            form.isFinish = this.form.isFinish
                            form.returnVisitType = "2"
                            if (form.isFinish == 1) {
                                form.isResolved = this.form.isResolved
                                form.isSatisfaction = this.form.isSatisfaction
                                form.returnVisitText = this.form.returnVisitText
                            }
                        }

                    }
                    let startingTime = this.form.startingTime ? moment(this.form.startingTime).format("YYYY-MM-DD HH:mm") : '';
                    let fileUrlList = fileList
                    api.serviceWorkOrder({ ...form, startingTime, fileUrlList }).then(res => {
                        console.log(res, '======');
                        if (res.code == "200") {
                            this.handleCancel()
                            this.$message.success('提交成功');
                            this.$emit('resetSearchForm')
                            api.removeWorkOrderDraft({ source: 'web' }).then(res => {

                            })
                        } else {
                            this.$message.error(res.msg);
                        }
                        this.confirmLoading = false;
                    })
                }
            });

        },
        handleCancelText() {
            api.removeWorkOrderDraft({ source: 'web' ,projectId:''}).then(res => {

            })
            this.$emit('resetSearchForm')
            this.handleCancel()
        },
        handleOkText() {
            console.log(this.form.fileUrlList, '====================');
            let startingTime = this.form.startingTime ? moment(this.form.startingTime).format("YYYY-MM-DD HH:mm") : '';
            let solutionTime = this.form.solutionTime ? moment(this.form.solutionTime).format("YYYY-MM-DD HH:mm") : '';
            let fileUrlList = []
            this.form.fileUrlList.forEach((item) => {
                if (item.status === 'done') {
                    fileUrlList.push({
                        url: item.url || item.response.redirect_uri,
                        file: { type: item.type },
                        uid: item.uid,
                        name: item.name,
                        status: 'done',
                    });
                } else {
                }
            });
            api.saveWorkOrderDraft({ ...this.form, fileUrlList, startingTime, solutionTime, source: 'web' ,projectId:''}).then((res) => {
                if (res.code == 200) {
                    this.showVisble = false;
                    this.handleCancel()
                    this.$emit('resetSearchForm')
                }
            });
        },
        handleCancel(e) {
            Object.assign(this.form, this.$options.data().form)
            this.$emit('update:show', false)
        },
        showConfirm() {

            if (this.form.startingTime || this.form.demandDescribe || (this.form.demandSource == '4' && this.form.enterpriseProjectId)) {
                this.showVisble = true
                //     let _this = this
                //     this.$confirm({
                //         title: '保存草稿',
                //         content: '确定要保存为草稿吗？',
                //         onOk() {
                //             _this.handleCancel()
                //         },
                //         onCancel() {
                //             _this.handleCancel()
                //         },
                //     });
            } else {
                this.handleCancel()
            }


        },
        handleIMGCancel() {
            this.previewVisible = false;
        },
        async onSearch(value) {
            const res = await api.fuzzyretrieval({
                projectId: this.form.enterpriseProjectId,
                enterpriseName: value || '',
                page: 1,
                size: 100
            });
            if (res.code == "200") {
                this.enterpriseList = res.data.enterpriseList;
            } else {
                this.$message.error(res.msg);
            }
        },
        async onSearchName(value) {
            this.checkedValue = "";
            const res = await api.customerFuzzyretrieval({
                enterpriseId: this.form.enterpriseId,
                staffName: value,
                page: 1,
                size: 100
            });
            if (res.code == "200") {
                this.persionList = res.data.staffList;
            }
        },
        Changename(value) {
            console.log(value, '==========');
            const selectedOption = this.persionList.find(option => option.id === value[value.length - 1]);
            // this.form.linkmanPhone = selectedOption.phoneNumber// 联系方式
            console.log(selectedOption);
            this.form.appBindPhone = selectedOption?.phoneNumber;//APP绑定手机号
            this.form.linkmanName = selectedOption ? selectedOption.name : value;//APP绑定手机号

            if (Array.isArray(this.form.linkmanName)) {
                this.form.linkmanName = this.form.linkmanName[this.form.linkmanName.length - 1]
                // 是数组
            }
            console.log(this.form.linkmanName);
        },
        seta(aa) {
            for (let item = 0; item < aa.length; item++) {
                if (aa[item].childrenList && aa[item].childrenList.length > 0 && aa[item].demandUserList && !aa[item].demandUserList.length) {
                    this.seta(aa[item].childrenList);
                    aa[item].selectable = false
                } else if (aa[item].childrenList && aa[item].childrenList.length > 0 && aa[item].demandUserList && aa[item].demandUserList.length) {
                    console.log(aa[item]);
                    aa[item].selectable = false
                    if (aa[item].childrenList) {
                      this.seta(aa[item].childrenList);
                    }
                    for (let itt = 0; itt < aa[item].demandUserList.length; itt++) {
                        let a = aa[item].demandUserList[itt]
                        aa[item].childrenList.push({
                            organizationId: a.userId,
                            organizationName: a.userName,
                        })
                    };
                } else {
                    if (aa[item].demandUserList && aa[item].demandUserList.length) {
                        aa[item].selectable = false
                        for (let itt = 0; itt < aa[item].demandUserList.length; itt++) {
                            let a = aa[item].demandUserList[itt]
                            aa[item].childrenList.push({
                                organizationId: a.userId,
                                organizationName: a.userName,
                            })
                        }
                    }

                }
            }
        },

        async onSearchUserName(val) {
            let res = await api.getDemandUserTree({
                roleId: 3,
                workOrderId: this.id,
                phoneNumberOrName: val || ''
            })
            this.seta(res.data)
            console.log('res.data', res.data)
            this.treeData = res.data
        },
        async placeDownload(val) {
            let res = await api.placeDownload({ projectId: val })
            this.placeDownloadList = res.data
        },
        async tenantDownload(val) {
            let res = await api.tenantDownload({ projectId: val })
            this.tenantDownloadList = res.data
        },
        handleDemandSourceChange() {
            let demandSource = this.form.demandSource
            this.$refs.ruleForm.resetFields()
            Object.assign(this.form, this.$options.data().form)
            this.form.demandSource = demandSource
            console.log(this.form.demandSource);
            if (this.form.demandSource == 3) {
                this.form.demandType = '12'
                this.form.requireCallback = 1
            } else {
                this.form.demandType = '13'
                this.form.requireCallback = 0
            }
        },
        changePrincipalId(value, label, extra) {
            this.form.principalIdData = label[0]
            console.log(this.form.principalIdData);
        },
        changeProject(value, option) {
            this.form.servicePlace = undefined
            this.form.serviceMerchant = undefined
            this.placeDownload(value)
            this.tenantDownload(value)
            const selectedOption = this.projectMsgList.find(option => option.projectId === value);
            console.log(selectedOption);
            this.form.enterpriseProjectName = selectedOption.projectName
        },
        changeEnterprise(value, option) {
            const selectedOption = this.enterpriseList.find(option => option.enterpriseId === value);
            console.log(selectedOption);
            this.form.enterpriseIdList = selectedOption.enterpriseName
        },
        changeServicePlace(value) {
            const selectedOption = this.placeDownloadList.find(option => option.placeId === value);
            console.log(selectedOption);
            this.form.servicePlacetext = selectedOption.name
        },
        changeServiceMerchant(value) {
            const selectedOption = this.tenantDownloadList.find(option => option.commercialTenantId === value);
            console.log(selectedOption);
            this.form.serviceMerchanttext = selectedOption.name
        },
        changeSolutionInformation(value) {
            const selectedOption = this.actualColumns.find(option => option.key === value);
            console.log(selectedOption);
            this.form.solutionInformationText = selectedOption.name
        },
        changeDemandServerType(value) {
            const selectedOption = this.demandServiceTypeList.find(option => option.demandServerType === value);
            console.log(selectedOption);
            this.form.demandServerName = selectedOption.demandServerTypeName
        },
        disabledStartHours(){
            let currentTime =  moment()
            if(moment(this.form.startingDate).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
                return this.range(0, 24).splice(currentTime.hour() + 1)
            }
        },
        disabledStartMinutes(){
            let currentTime =  moment()
            if(moment(this.form.startingDate).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
                if(currentTime.hour() == moment(this.form.startingH).format('HH')){
                    return this.range(0, 60).splice(currentTime.minute() + 1)
                }
                if(!this.form.startingH){
                    return this.range(0, 60).splice(currentTime.minute() + 1)
                }
            }
        },
        openChange(){
            if(!this.form.solutionDate){
                this.form.solutionH =''
            }
        },
        openChange2(){
            if(moment(this.form.solutionTime).isAfter(this.form.startingTime)){
                    console.log('结束时间大于开始时间');
                }else{
                    this.form.solutionTime = ''
                    this.form.solutionDate = ""
                    this.form.solutionH = ""
                }
        }
    },
};
</script>

<style scoped>
.ant-form-item {
    margin-bottom: 0;
}

h3 {
    margin-top: 20px;
}
</style>